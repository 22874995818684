import Box from "@mui/material/Box";
import { AppConfig } from "src/appConfig";
export default function Banner() {

    return (
        <>
            <Box
                component="img"
                src={AppConfig.client.logoSrc}
                alt={AppConfig.client.logoAlt}
                sx={{
                    position: "relative",
                    width: "150px",
                    left: "10px",
                    zIndex: 1251,
                    "@media screen and (max-width: 600px)": { width: "100px" }
                }}
            />
            <Box
                sx={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontVariantCaps: "all-small-caps",
                    "@media screen and (max-width: 480px)": { fontSize: "1.4rem" },
                    "@media screen and (max-width: 460px)": { fontSize: "1.3rem" },
                    "@media screen and (max-width: 435px)": { fontSize: "1.2rem" },
                    "@media screen and (max-width: 415px)": { fontSize: "1.1rem" },
                    "@media screen and (max-width: 390px)": { fontSize: "1rem" },
                    "@media screen and (max-width: 370px)": { fontWeight: "normal" },
                    "@media screen and (max-width: 350px)": { fontSize: "0.9rem" },
                    "@media screen and (max-width: 315px)": { display: "none" }

                }}
            >
                {AppConfig.client.bannerElem}
            </Box>

        </>
    );
}