import { createRef, type JSX, lazy, Suspense } from "react";
import { useLocation } from "react-router";
import FallbackSpinner from "selign-component-lib/FallbackSpinner";
import SignInPage from "src/features/auth/SignInPage";
import SignOutCallbackPage from "src/features/auth/SignOutCallbackPage";
import SignOutPage from "src/features/auth/SignOutPage";

const CoursesPage = lazy(() => import("src/features/courses/CoursesPage"));
const HomePage = lazy(() => import("src/features/home/HomePage"));
const UserProfilePage = lazy(() => import("src/features/userProfile/UserProfilePage"));
const ResetPasswordPage = lazy(() => import("src/features/auth/ResetPasswordPage"));
const DownloadsPage = lazy(() => import("src/features/downloads/DownloadsPage"));
export interface AppRoute {
    path: string;
    element: JSX.Element;
    nodeRef: React.RefObject<HTMLElement | unknown>;
    label: string;
    inMenu: boolean;
    auth: false | "Authenticated" | { areaName: string, moduleName: string | undefined; };

    /** "left" used when undefined */
    alignmentGroup?: "left" | "right";


}

const appRoutes: Array<AppRoute> = [
    {
        path: "/", element: <Suspense fallback={<FallbackSpinner />}><HomePage /></Suspense>,
        nodeRef: createRef(),
        label: "Home",
        inMenu: true,
        auth: "Authenticated"
    },
    {
        path: "/courses", element: <Suspense fallback={<FallbackSpinner />}><CoursesPage /></Suspense>,
        nodeRef: createRef(),
        label: "Courses",
        inMenu: true,
        auth: "Authenticated"
    },
    {
        path: "/signin",
        element: <SignInPage />,
        nodeRef: createRef(),
        label: "Sign In",
        inMenu: false,
        auth: false
    },
    {
        path: "/signout",
        element: <SignOutPage />,
        nodeRef: createRef(),
        label: "Sign Out",
        inMenu: true,
        alignmentGroup: "right",
        auth: "Authenticated"
    },
    {
        path: "/signout-callback",
        element: <SignOutCallbackPage />,
        nodeRef: createRef(),
        label: "Sign Out Callback",
        inMenu: false,
        auth: false,

    },
    {
        path: "/resetpassword",
        element: <Suspense fallback={<FallbackSpinner />}><ResetPasswordPage /></Suspense>,
        nodeRef: createRef(),
        label: "Reset Password",
        inMenu: false,
        auth: false
    },
    {
        path: "/register",
        element: <Suspense fallback={<FallbackSpinner />}><UserProfilePage action="register" /></Suspense>,
        nodeRef: createRef(),
        label: "Register",
        inMenu: false,
        auth: false
    },
    {
        path: "/userprofile",
        element: <Suspense fallback={<FallbackSpinner />}><UserProfilePage action="userProfile" /></Suspense>,
        nodeRef: createRef(),
        label: "User Profile",
        inMenu: true,
        auth: "Authenticated"
    },
    {
        path: "/downloads",
        element: <Suspense fallback={<FallbackSpinner />}><DownloadsPage /></Suspense>,
        nodeRef: createRef(),
        label: "Downloads",
        inMenu: true,
        auth: "Authenticated"
    }
];

function useCurrentAppRoute() {
    const location = useLocation();
    return appRoutes.find(r => r.path.toLowerCase() === location.pathname.toLowerCase());
}


export { appRoutes, useCurrentAppRoute };
