import { AuthUser } from "selign-domain-model/dist/types/authUser";
import { AppRoute } from "./appRoutes";

export function isAppRouteAuthorized(route: AppRoute, authUser: AuthUser | undefined): boolean {
    if (route.auth === false) {
        return true;
    }
    if (!authUser) {
        return false;
    }
    if (route.auth === "Authenticated") {
        return true;
    }
    const authArea = route.auth.areaName.toLowerCase();
    const area = authUser.areas?.find((a) => a.name.toLowerCase() === authArea);
    if(!area) {
        return false; 
    }
    if (!route.auth.moduleName) {
        return true;
    }
    const authModule = route.auth.moduleName.toLowerCase();
    const module = area.modules?.find((m) => m.name.toLowerCase() === authModule);
    if (!module) {
        return false;
    }
    return true;
}