import type { DomainRecordValid } from "selign-domain-model";
import type { userProfileDS } from "src/app/domainHelpers";
import { AppConfig } from "src/appConfig";
import { splitApi } from "src/app/services/splitApi";
import { store } from "src/app/store";
import getUserCourseRequirementGroups from "./courseRequirementGroups";
import getUserCourseRequirement from "./courseRequirementsMap";
import type { ConditionalCoursePrerequisite, Course, CoursePrerequisite } from "src/features/courses/legacy/course";
import type { UserCourse, UserCourseStatus } from "src/features/courses/legacy/userCourse";
import type { UserCourseHistory, UserCourseHistoryOnlineSelfPacedModule } from "src/features/courses/legacy/userCourseHistory";
import userManager from "src/features/auth/oidcConfig";

type UserProfileDS = typeof userProfileDS;

export interface CourseSummary {
    course: Course;
    userCourse: UserCourse;
    history?: UserCourseHistory;
}

type CourseHistoriesDTO =
    {
        courseUid: string,
        kind: "UserCourseHistoryOnlineSelfPacedModule",
        sessions: {
            start: string,
            end: string,
            creditReceived: boolean;
        }[];
    };


async function getCourseHistories(): Promise<CourseHistoriesDTO[]> {
    const token = (await userManager.getUser())?.access_token;

    const response = await fetch(`${AppConfig.url.api}/mydata/coursehistories`, {
        headers: { "Authorization": `Bearer ${token}` },
        credentials: "include"
    });

    const data: CourseHistoriesDTO[] = await response.json();

    return data;
}

async function getUserCourses(courseData: Course[], userProfile?: DomainRecordValid<UserProfileDS, "read">): Promise<UserCourse[]> {
    const token = (await userManager.getUser())?.access_token;

    const response = await fetch(`${AppConfig.url.api}/mydata/coursestatus`, {
        headers: { "Authorization": `Bearer ${token}` },
        credentials: "include"
    });
    const data: { courseUid: string, latestDateCompleted: string, status: string; }[] = await response.json();
    
    const userCourses = courseData.map(course => {
        const match = data.find(f => f.courseUid.toLowerCase() === course.uid.toLowerCase());
        const courseCompulsion = getUserCourseRequirement(course.uid, userProfile);
        
        if (match) {
            let expiryDate: string = "";
             if (course.timeToExpiry && match.latestDateCompleted) {
                 const ldc = new Date(match.latestDateCompleted);
                 expiryDate = new Date(ldc.setMonth(ldc.getMonth() + Number(course.timeToExpiry.value))).toString();
             }
            return {
                uid: "",
                courseUid: course.uid,
                compulsion: courseCompulsion.compulsion,
                compulsionDetail: courseCompulsion.compulsionDetail,
                latestDateCompleted: match.latestDateCompleted || "",
                expiryDate: expiryDate,
                status: scormCompletionStatusToUserCourseStatus(match.status)
            };
        } else {
            return {
                uid: "",
                courseUid: course.uid,
                compulsion: courseCompulsion.compulsion,
                compulsionDetail: courseCompulsion.compulsionDetail,
                latestDateCompleted: "",
                status: "Not Started" as UserCourseStatus
            };
        }
    });

    return userCourses;
}

function scormCompletionStatusToUserCourseStatus(completionStatus: string): UserCourseStatus {
    switch (completionStatus.toLowerCase()) {
        case "completed":
            return "Completed";
        case "incomplete":
            return "In Progress";
        default:
            return "Not Started";
    }
}

// mock functions to imitate async API calls
// export function fetchCourses() {
//     return new Promise<Course[]>(
//         (resolve) =>
//             //@ts-ignore
//             setTimeout(() => resolve(jsonCourse.courses), 500)
//     );
// }

// export function fetchUserCourses() {
//     return new Promise<UserCourse[]>(
//         (resolve) =>
//             setTimeout(() => resolve(getUserCourses()), 500)
//     );
// }

// export function fetchUserCourseHistories() {
//     return new Promise<UserCourseHistory[]>(
//         (resolve) =>
//             setTimeout(() => resolve([]), 500)
//     );
// }

function isCoursePrerequisite(prereq: CoursePrerequisite | ConditionalCoursePrerequisite): prereq is CoursePrerequisite {
    return (prereq as CoursePrerequisite).name !== undefined;
}

async function getCourseSummaries(userProfile?: DomainRecordValid<UserProfileDS, "read">): Promise<CourseSummary[]> {
    const result = store.dispatch(splitApi.endpoints.getJsonData.initiate({schemaName: "course", actions: ["ViewCourse"], includeGroups: false}));    
    const courseData = (await result.unwrap()).map(courseDatum => Object.assign({}, courseDatum.data, {uid: courseDatum.uid})) as Course[];
    result.unsubscribe();
    // const courseData = await ApiFetchOLD(CoursesApiEndpoints.GetAllCourses, undefined, (await userManager.getUser())?.access_token);
    
    const userCourses = await getUserCourses(courseData, userProfile);
    const userCourseHistories = await getCourseHistories();    

    const summaries = courseData.map(course => {
        const userCourse = userCourses.find(s => s.courseUid.toUpperCase() === course.uid.toUpperCase());
        const userCourseHistory = userCourseHistories.find(s => s.courseUid.toUpperCase() === course.uid.toUpperCase());
        const uch: UserCourseHistoryOnlineSelfPacedModule = {
            kind: "UserCourseHistoryOnlineSelfPacedModule",
            history: userCourseHistory?.sessions.map(ss => ({ creditReceived: ss.creditReceived, end: ss.end, start: ss.start })) || []
        };

        let prereqsSatisfied = true;
        if (course.requiredPrereqs) {
            // TODO: Handle ConditionalCoursePrerequisite
            // TODO: Handle prerequisites with a takenWithin time interval

            course.requiredPrereqs.forEach(rp => {
                const logic = rp.logic;
                let rpAtLeastOneSatisifed = false;
                rp.courses.forEach(rpc => {

                    // assume all rpc's are CoursePrerequisites for now
                    if (isCoursePrerequisite(rpc)) {
                        const ucf = userCourses.find(uc => uc.courseUid === rpc.uid);
                        if(ucf) {
                            // userCourse found
                            if(ucf.latestDateCompleted && ucf.latestDateCompleted.length > 0) {
                                // the prereq is satisfied for both logic types (and/or)
                                rpAtLeastOneSatisifed = true;
                            } else {
                                if (logic === "and") {
                                    // logic makes this a must have prereq
                                    prereqsSatisfied = false;
                                }    
                            }  
                            // if(!ucf.latestDateCompleted || ucf.latestDateCompleted.length <= 0) {
                            //     // the prereq is satisfied for both logic types (and/or)
                            //     rpAtLeastOneSatisifed = true;
                            // } else {
                            //     if (logic === 'and') {
                            //         // logic makes this a must have prereq
                            //         prereqsSatisfied = false;
                            //     }    
                            // }                                
                        } else {
                            // userCourse not found
                            if (logic === "and") {
                                // logic makes this a must have prereq
                                prereqsSatisfied = false;
                            }
                        }
                    }
                });
                if(logic === "or" && !rpAtLeastOneSatisifed) {
                    prereqsSatisfied = false;
                }
            });
        } else {
            // no required prereqs
            prereqsSatisfied = true;
        }

        const newCourse = {...course};
        Object.defineProperty(newCourse, "prereqsSatisfied", {value: prereqsSatisfied, writable: true});

        //course.prereqsSatisfied = prereqsSatisfied;

        if (!userCourse) throw new Error(`Mock data invalid: Unable to find user course summary for ${course.uid}`);

        return {            
            course: newCourse,
            userCourse: userCourse,
            history: userCourseHistory
                ? {
                    courseUid: course.uid,
                    uid: "",
                    details: uch
                }
                : undefined
        };
    });

    return summaries;
}

export async function fetchCourseSummaries(userProfile?: DomainRecordValid<UserProfileDS, "read">) {    
    const cs = await getCourseSummaries(userProfile);
    const token = (await userManager.getUser())?.access_token || "";
    const ucrgs = userProfile ? await getUserCourseRequirementGroups(userProfile, cs, token) : [];
    return { courseSummaries: cs, userCourseRequirementGroups: ucrgs};
    // return new Promise<CourseSummary[]>(
    //     (resolve) =>
    //         setTimeout(() =>
    //             resolve(getCourseSummaries())

    //                 // courseData.map(course => {
    //                 //     const summary = UserCourseSummaryData.find(s => s.uuid === course.uuid);
    //                 //     const history = UserCourseHistory?.find(h => h.uuid === course.uuid);   
    //                 //     if (!summary)
    //                 //         throw new Error(`Mock data invalid: Unable to find user course summary for ${course.uuid}`); 
    //                 //     return {
    //                 //         course: course,
    //                 //         summary: summary,
    //                 //         history: history
    //                 //     };
    //                 // }))
    //             , 500)
    // );
}