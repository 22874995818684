import { useNavigate } from "react-router";
import { SignoutCallbackComponent } from "redux-oidc";
import { useAppDispatch } from "src/app/hooks";
import userManager from "./oidcConfig";
export default function SignOutCallbackPage() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const callback = (error?: unknown) => {
        dispatch({ type: "USER_SIGNOUT" }); // action destroys data in redux store
        //if (error && typeof error === "object" && "error" in error) console.log("SignOutCallback error:", [error]);
        console.log("SignOutCallback", [error]);        
        navigate("/signin", { replace: true });
    };
    return (
        //@ts-expect-error type error with redux-oidc beta
        <SignoutCallbackComponent
            userManager={userManager}
            successCallback={callback}
            errorCallback={(error) => callback(error)}
        >
            <div></div>
        </SignoutCallbackComponent>
    );
}