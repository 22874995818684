import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { DomainRecordValid, AuthUser } from "selign-domain-model";
// import type { RootState } from 'src/app/store';
import { doRegister, doSignIn, doSignOut, doSilentSignin, updateUserProfile } from "src/features/auth/authAPI";
import { type userProfileDS } from "src/app/domainHelpers";
import { AppState } from "src/app/store";


// this is really more of a user slice
// either separate or rename

type UserProfileDS = typeof userProfileDS;
export interface AuthCredentials {
    email: string;
    password: string;
    confirmPassword?: string;
    redirectTo: string;
}

export interface AuthState {
    isAuthenticated: boolean;
    status: "idle" | "signin-pending" | "signout-pending" | "registration-pending" | "update-pending" | "succeeded" | "failed" | "update-failed";    
    error: string | undefined;
    user: AuthUser | undefined;
    
}

const initialState: AuthState = {
    isAuthenticated: false,
    status: "idle",    
    error: undefined,
    user: undefined
};

export const doRegisterAsync = createAsyncThunk(
    "auth/doRegister",
    async (values: {userProfile: DomainRecordValid<UserProfileDS, "create">}) => {
        const response = await doRegister(values.userProfile);
        return response;
    }
);
export const doSilentSignInAsync = createAsyncThunk(
    "auth/doSilentSignIn",
    async () => {
        const response = await doSilentSignin();
        return response;
    }
);

export const doSignInAsync = createAsyncThunk(
    "auth/doSignIn",
    async (credentials: AuthCredentials) => {           
        const response = await doSignIn(credentials.email, credentials.password);        
        return response;
    }
);

export const updateUserProfileAsync = createAsyncThunk(
    "auth/updateUserProfile",
    async (values: {userProfile: DomainRecordValid<UserProfileDS, "update">}, thunkAPI) => {
        //TODO getState should be typed, and token & newEmail are going to break with all those ?
        const state: AppState = thunkAPI.getState() as AppState;
        const token = state.oidc.user?.access_token ?? "";
        const newEmail = state.auth.user?.email !== values.userProfile.value["email"];

        const response = await updateUserProfile(values.userProfile, token, newEmail);
        return response;
    }
);

export const doSignOutAsync = createAsyncThunk(
    "auth/doSignOut",
    async () => {
        await doSignOut();
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(doSignInAsync.pending, (state) => { state.status = "signin-pending"})
            .addCase(doSignInAsync.fulfilled, (state, action) => {                
                
                state.isAuthenticated = true;
                state.status = "succeeded";
                state.user = action.payload;
                state.error = undefined;
            })
            .addCase(doSignInAsync.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.status = "failed";
                state.user = undefined;
                state.error = action.error.message;
            })
            .addCase(doSignOutAsync.pending, (state) => { 
                state.isAuthenticated = false;
                state.status = "signout-pending";
                state.user = undefined;
                state.error = undefined;                
            })
            .addCase(doSignOutAsync.fulfilled, (state, action) => {
                state.isAuthenticated = false;
                state.status = "idle";
                state.user = undefined;
                state.error = undefined;
            })
            .addCase(doSignOutAsync.rejected, (state, action) => {                
                state.isAuthenticated = false;
                state.status = "failed";
                state.user = undefined;
                state.error = action.error.message;
            })
            .addCase(doRegisterAsync.pending, (state, action) => {
                state.isAuthenticated = false;
                state.status = "registration-pending";
                state.user = undefined;
                state.error = undefined;
            })
            .addCase(doRegisterAsync.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.status = "failed";
                state.user = undefined;
                state.error = action.error.message;
            })
            .addCase(doRegisterAsync.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.status = "succeeded";
                state.user = action.payload;
                state.error = undefined;
            })
            .addCase(updateUserProfileAsync.pending, (state, action) => {
                state.status = "update-pending";
            })
            .addCase(updateUserProfileAsync.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.user = action.payload;
                state.error = undefined;
            })
            .addCase(updateUserProfileAsync.rejected, (state, action) => {
                state.status = "update-failed";                
                state.error = action.error.message;
            })
            .addCase(doSilentSignInAsync.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.status = "succeeded";
                state.user = action.payload;
                state.error = undefined;
            })
            .addCase(doSilentSignInAsync.rejected, (state, action) => {
                state.isAuthenticated = false;
                state.status = "failed";
                // state.user = undefined;
                state.error = action.error.message;
            })
            .addCase(doSilentSignInAsync.pending, (state) => { 
                state.isAuthenticated = false;
                state.status = "signin-pending";
                // state.user = undefined;
                state.error = undefined;
            });
    }
});

// export const selectAuthUser = (state: RootState) => state.authUser.authUser
export const selectAuthUser = (state: { auth: any; }) => (state.auth as AuthState);

export default authSlice.reducer;