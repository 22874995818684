import { Action, combineReducers, configureStore } from "@reduxjs/toolkit";
import { loadUser, reducer as oidcReducer, type UserState as OidcUserState } from "redux-oidc";
import authReducer, { type AuthState } from "src/features/auth/authSlice";
import coursesReducer from "src/features/courses/coursesSlice";
import userManager from "src/features/auth/oidcConfig";
import { splitApi } from "./services/splitApi";

const appReducers = combineReducers({
    //userCourses: coursesReducer,
    oidc: oidcReducer,
    auth: authReducer,
    userCourses: coursesReducer,
    [splitApi.reducerPath]: splitApi.reducer
});

// type CombinedAppState = CombinedState<
//     {
//         //userCourses: CoursesState;
//         oidc: OidcUserState;
//         auth: AuthState;
//         //[splitApi.reducerPath]: ReturnType<typeof splitApi.reducer>;
//     }> | undefined;


// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: Action) => {
    if (action.type === "USER_SIGNOUT") {
        return appReducers(undefined, action);
    }

    return appReducers(state, action);
};


const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredPaths: ["oidc.user"],
            ignoredActions: ["redux-oidc/USER_FOUND"]
        }
    }).concat(splitApi.middleware)
});

loadUser(store, userManager);

export { store };
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;