import type { UserManagerSettings } from "oidc-client";
import { createUserManager } from "redux-oidc";
import { AppConfig } from "src/appConfig";



// window.location.origin?
const appBaseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;

// these URLs need to match the auth project's configuration (Config.cs)
// could get them from AUTH_URL/.well-known/openid-configuration

const oidcConfig: UserManagerSettings = {
    authority: AppConfig.url.auth,
    client_id: "lms-web-client",
    redirect_uri: `${appBaseUrl}/signin-callback`,
    response_type: "code",
    scope: "openid profile SelignAPI",
    post_logout_redirect_uri: `${appBaseUrl}/signout-callback`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${appBaseUrl}/signinSilent.html`,
};

const userManager = createUserManager(oidcConfig);

export default userManager;