import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { ClickAwayListener, type BoxProps } from "@mui/material";
import { useRef, useState } from "react";
import { useLocation } from "react-router";
import AppLink from "src/components/AppLink";
import { type AppRoute } from "./appRoutes";
import { NavMenuColumn, NavMenuContainer, NavMenuFlexColumn, NavMenuHamburgerButton, NavMenuHamburgerButtonWrapper, NavMenuHamburgerLinkContainer, NavMenuLinkWrapper } from "./topNavMenuComponents";
import { useWillContentsWrap } from "./useWillContentsWrap";
export default function TopNavMenu(props: { routes: Array<AppRoute>; } & BoxProps) {
    const { routes, sx, ...rest } = props;
    const location = useLocation();
    const rootRef = useRef(null);
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
    const haveNavMenuItems = routes.length > 0;
    const contentsWillWrap = useWillContentsWrap(rootRef, 155, false);

    function handleHamburgerMenuClickAway() {
        if (isHamburgerMenuOpen)
            setIsHamburgerMenuOpen(false);
    }

    function handleHamburgerMenuClick() {
        setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
    }

    function handleNavMenuLinkWrapperClick(e: React.MouseEvent) {
        const elem = e.target as Element;
        if (elem.tagName === "DIV") {
            elem.getElementsByTagName("a")[0].click();
        }
        if (elem.tagName === "A") {
            (elem as HTMLAnchorElement).click();
        }
        setIsHamburgerMenuOpen(false);
    }

    function getNavMenuItems(group: "all" | "left" | "right") {
        if (contentsWillWrap && !isHamburgerMenuOpen)
            return null;

        return routes
            .filter(i => group === "all" ? true : group === "right" ? i.alignmentGroup === "right" : i.alignmentGroup !== "right")            
            .map((ni, idx) =>
                <NavMenuLinkWrapper key={idx} onClick={handleNavMenuLinkWrapperClick} isHamburger={contentsWillWrap} isActive={location.pathname === ni.path}>
                    <AppLink path={ni.path} label={ni.label} />                    
                </NavMenuLinkWrapper>);
    }

    return (
        <NavMenuContainer
            {...rest}
            isHamburger={contentsWillWrap}
            ref={rootRef}
            sx={sx}
        >
            {haveNavMenuItems && contentsWillWrap &&
                <ClickAwayListener onClickAway={handleHamburgerMenuClickAway}>
                    <NavMenuColumn >
                        <NavMenuHamburgerButtonWrapper>
                            <NavMenuHamburgerButton
                                sx={{ marginLeft: "auto", display: "block" }}
                                onClick={handleHamburgerMenuClick}>
                                {isHamburgerMenuOpen ? <MenuOpenIcon /> : <MenuIcon />}
                            </NavMenuHamburgerButton>
                        </NavMenuHamburgerButtonWrapper>
                        {isHamburgerMenuOpen && <NavMenuHamburgerLinkContainer>{getNavMenuItems("all")}</NavMenuHamburgerLinkContainer>}
                        {!isHamburgerMenuOpen && getNavMenuItems("all")}
                    </NavMenuColumn>
                </ClickAwayListener>}

            {haveNavMenuItems && !contentsWillWrap && <NavMenuFlexColumn>{getNavMenuItems("left")}</NavMenuFlexColumn>}
            {haveNavMenuItems && !contentsWillWrap && <NavMenuFlexColumn sx={{ marginLeft: "auto" }}>{getNavMenuItems("right")}</NavMenuFlexColumn>}
        </NavMenuContainer>
    );
}