import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Navigate, Link as RouterLink, useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppConfig } from "src/appConfig";
import { doSignInAsync } from "src/features/auth/authSlice";
import ForgottenPassword from "src/features/auth/ForgottenPassword";
export default function SignInForm() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [fpOpen, setFpOpen] = useState(false); //Forgotten Password - Dialog    

    const dispatch = useAppDispatch();
    const location = useLocation();
    const authUser = useAppSelector(state => state.auth);    

    const pending = authUser.status.includes("pending");
    const authError = pending ? undefined : authUser.error;
    const { from } = location.state || { from: { pathname: "/" } };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target;

        switch (target.name) {
            case "email":
                setEmail(target.value);
                break;
            case "password":
                setPassword(target.value);
                break;
            case "rememberMe":
                setRememberMe(target.checked);
                break;
        }
    };
    const handleFpClickOpen = () => { setFpOpen(true) };
    const handleFpClose = () => { setFpOpen(false) };
    const handleSignInClick = () => { dispatch(doSignInAsync({ email, password, redirectTo: from.pathname })) };

    if (!authError && authUser.isAuthenticated) return (<Navigate to={"/"} />);

    return (
        <Box>
            <form noValidate autoComplete="off">
                {authError && <Typography color="error">{authError}</Typography>}
                <TextField name="email" variant="filled" label="Email" type="email" fullWidth value={email} onChange={handleInputChange}></TextField>
                <TextField name="password" variant="filled" label="Password" type="password" fullWidth value={password} onChange={handleInputChange}></TextField>
                <FormControlLabel
                    style={{ visibility: "hidden" }}
                    control={<Checkbox name="rememberMe" color="primary" value={rememberMe} onChange={handleInputChange} disabled={true} />}
                    label="Remember me"
                />
                <Button variant="contained" color="primary" style={{ float: "right" }} onSubmit={undefined} onClick={handleSignInClick} loading={pending} type="submit">Sign In</Button>
            </form>
            <Button color="primary" onClick={handleFpClickOpen}>Forgot your password?</Button>
            <br />
            <Button color="primary" component={RouterLink} to="/register">Register as a new user</Button>
            <br />
            <Button color="primary" href={`mailto:${AppConfig.client.helpEmail}`}>Need Assistance?</Button>
            <ForgottenPassword onClose={handleFpClose} open={fpOpen} initialEmail={email} />
        </Box>
    );
}