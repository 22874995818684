import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { useCurrentAppRoute } from "src/features/nav/appRoutes";
import { NotAuthorizedPage } from "./NotAuthorizedPage";
import { isAppRouteAuthorized } from "src/features/nav/isAppRouteAuthorized";
import { doSilentSignInAsync } from "src/features/auth/authSlice";
import  FallbackSpinner from "selign-component-lib/FallbackSpinner";


export default function AuthRouteElementWrapper({ children }: { children: React.JSX.Element; }) {
    const dispatch = useAppDispatch();
    const authUser = useAppSelector(state => state.auth.user);
    const currentAppRoute = useCurrentAppRoute();

    const isOidcUserLoading = useAppSelector(state => state.oidc.isLoadingUser);
    const isOidcUserLoaded = useAppSelector(state => state.oidc.user !== null);

    let isAuthUserLoading = useAppSelector(state => state.auth.status.includes("pending"));
    const isAuthUserLoaded = useAppSelector(state => state.auth.user !== undefined);

    if (isOidcUserLoaded && !isAuthUserLoaded && !isAuthUserLoading) {
        // silently sign-in the user if they are already authenticated with the OIDC provider (prevents having to sign-in again after a page refresh)
        dispatch(doSilentSignInAsync());
        isAuthUserLoading = true;
    }

    if (!currentAppRoute) return <NotAuthorizedPage />;

    const isAuthorized = isAppRouteAuthorized(currentAppRoute, authUser);

    if (!isAuthUserLoaded || !isAuthorized) {
        if (isOidcUserLoading || isAuthUserLoading) {
            return <FallbackSpinner />;
        }
    }
    
    if (isAuthorized) {
        return children;
    } else {
        return <NotAuthorizedPage />;
    }
}