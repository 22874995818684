import { domainLibrary } from "omd-domain-model";
import logo from "src/assets/logo.png";

const API_URL = import.meta.env.VITE_API_URL === "PATTERN" ? `${window.location.protocol}//api.${window.location.hostname}` : import.meta.env.VITE_API_URL;
const AUTH_URL = import.meta.env.VITE_AUTH_URL === "PATTERN" ? `${window.location.protocol}//auth.${window.location.hostname}` : import.meta.env.VITE_AUTH_URL;
const BYTE_LIMIT = Number(import.meta.env.VITE_BYTE_LIMIT);
const COURSE_BYTE_LIMIT = Number(import.meta.env.VITE_COURSE_BYTE_LIMIT);
const CLIENT_TITLE = String(import.meta.env.VITE_CLIENT_TITLE);
const CLIENT_DESCRIPTION = String(import.meta.env.VITE_CLIENT_DESCRIPTION); 
const CLIENT_LOGO_ALT = String(import.meta.env.VITE_LOGO_ALT);  
const CLIENT_HELP_EMAIL = String(import.meta.env.VITE_CLIENT_HELP_EMAIL);
const CLIENT_CERT_TITLE = String(import.meta.env.VITE_CLIENT_CERT_TITLE);
const CLIENT_CERT_SIG = String(import.meta.env.VITE_CLIENT_CERT_SIG);
const CLIENT_CERT_X = Number(import.meta.env.VITE_CLIENT_CERT_X) || 0;
const CLIENT_CERT_Y = Number(import.meta.env.VITE_CLIENT_CERT_Y) || 0;
if (API_URL === undefined || AUTH_URL === undefined) throw Error(`Invalid app configuration.`);

//TODO workout a better solution to bannerElem and logosrc and favIcons etc

export const AppConfig = {
    dl: domainLibrary,
    url: {
        api: API_URL,
        auth: AUTH_URL
    },
    limits: {
        byte: BYTE_LIMIT,
        courseByte: COURSE_BYTE_LIMIT
    },
    client: {
        title: CLIENT_TITLE,        
        description: CLIENT_DESCRIPTION,
        logoSrc: logo,
        logoAlt: CLIENT_LOGO_ALT,
        bannerElem: <>Oregon National Guard<br/>Learning Management System</>,
        helpEmail: CLIENT_HELP_EMAIL,
        cert: {
            title: CLIENT_CERT_TITLE,
            sigName: CLIENT_CERT_SIG,
            sigOffset: {
                x: CLIENT_CERT_X,
                y: CLIENT_CERT_Y
            }
        }
    }
} as const;