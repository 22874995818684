import IconButton, { type IconButtonProps } from "@mui/material/IconButton";
import Box, { type BoxProps } from "@mui/material/Box";
import { type SxProps, type Theme } from "@mui/material/styles";

//TODO Get the colors specified below from the Theme

const slideIn: SxProps<Theme> = {
    "@keyframes slideIn": {
        from: {
            right: "-50%",
            opacity: 0.001
        },
        to: {
            right: 0,
            opacity: 1
        }
    },
};

const fadeIn: SxProps<Theme> = {
    "@keyframes fadeIn": {
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    },
};

const NavMenuHamburgerMixin: SxProps<Theme> = {
    position: "absolute",
    left: 0,
    zIndex: "inherit"
};

const hamburgerLinkWrapperMixin: SxProps<Theme> = {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1rem",
    marginLeft: "1rem",
    borderBottom: "1px solid #5E7180"
};

const wideLinkWrapperMixin: SxProps<Theme> = {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    height: "100%",
    display: "flex",
    alignItems: "center"
};

const hamburgerLinkMixin: SxProps<Theme> = {
    backgroundColor: " #173049",
    "a": { color: "white" }
};

const wideLinkMixin: SxProps<Theme> = {
    backgroundColor: " #173049",
    "a": { color: "white" }
};

export function NavMenuLinkWrapper(props: { isHamburger: boolean, isActive: boolean; } & BoxProps) {

    const { isHamburger, isActive, sx, ...rest } = props;
    return (
        <Box
            sx={[{
                whiteSpace: "nowrap",
                cursor: "pointer",

            },
            (isHamburger ? hamburgerLinkWrapperMixin : wideLinkWrapperMixin),
            (isActive ? isHamburger ? hamburgerLinkMixin : wideLinkMixin : {}),
            ...(Array.isArray(sx) ? sx : [sx])]}
            {...rest}
        />
    );
}

export function NavMenuContainer(props: { isHamburger: boolean; } & BoxProps) {
    const { isHamburger, sx, ...rest } = props;
    return (
        <Box
            sx={[
                {
                    height: isHamburger ? "unset" : "100%",
                    width: "100%",
                    display: "flex",
                    flexWrap: "nowrap",
                    overflow: "hidden",
                    backgroundColor: "inherit"
                },
                (isHamburger ? NavMenuHamburgerMixin : {}),
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            component={"div"}
            {...rest}
        />
    );
}
export function NavMenuColumn(props: BoxProps) {
    const { sx, ...rest } = props;
    return (
        <Box
            sx={[
                {
                    display: "block",
                    width: "100%"
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        />
    );
}
export function NavMenuFlexColumn(props: BoxProps) {
    const { sx, ...rest } = props;
    return (
        <Box
            sx={[
                {
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    position: "relative",
                    animation: "slideIn 800ms"
                    
                },
                slideIn,
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        />
    );
}
export function NavMenuHamburgerLinkContainer(props: BoxProps) {
    const { sx, ...rest } = props;
    return (
        <Box
            sx={[
                {
                    animation: `fadeIn 750ms ease-in`
                },
                fadeIn,
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        />
    );
}

export function NavMenuHamburgerButtonWrapper(props: BoxProps) {
    const { sx, ...rest } = props;
    return (
        <Box
            sx={[
                {
                    width: "100%"
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        />
    );
}

export function NavMenuHamburgerButton(props: IconButtonProps) {
    const { sx, ...rest } = props;
    return (
        <IconButton
            sx={[
                {
                    marginLeft: "auto !important",
                    display: "block !important",
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        />
    );
}