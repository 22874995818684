import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { AppConfig } from "src/appConfig";


interface ForgottenPasswordProps {
    onClose: () => void;
    open: boolean;
    initialEmail: string;
}

function ForgottenPassword(props: ForgottenPasswordProps) {
    const { onClose, initialEmail, open } = props;
    const [email, setEmail] = useState(initialEmail);
    const [submitting, setSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = (e: React.MouseEvent) => { 
        onClose();
        
        const formData = new FormData();
        formData.append("Email", email);
        setSubmitting(true);

        fetch(`${AppConfig.url.auth}/Identity/Account/ForgotPassword`, {
            method: "post",
            body: formData
        }).then(success => {
            enqueueSnackbar("Please check your email to reset your password.", { variant: "info" });
            setSubmitting(false);
        }).catch(rejected => {
            enqueueSnackbar("An error occured while processing your Forgotten Password Request. Please try again later or contact help@ngor-lms.com.", { variant: "error" });
            setSubmitting(false);
        });
    };
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => { setEmail(event.target.value)};


    return (
        <Dialog onClose={handleCancel} open={open}>
            <DialogTitle>Forgotten Password Recovery</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To receive a link to reset your password, please enter your account email address below and click submit.
                </DialogContentText>
                <TextField
                    autoFocus
                    fullWidth                    
                    margin="dense"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleCancel} color="primary">
                    Cancel
          </Button>
                <Button name="submit" onClick={handleSubmit} color="primary" loading={submitting}>
                    Submit
          </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ForgottenPassword;