import { Box, Link, Typography } from "@mui/material";
import { Navigate } from "react-router";
import { useAppSelector } from "src/app/hooks";
import { AppConfig } from "src/appConfig";
import AppLink from "src/components/AppLink";

export function NotAuthorizedPage() {
    const authUser = useAppSelector(state => state.auth.user);

    if (authUser) {
        return (
            <Box sx={{
                textAlign: "center",
                width: "100%",

            }}>
                <Typography variant="h4">Not Authorized</Typography>
                <Typography variant="body1">Sorry, you are not authorized to access this page.</Typography>
                <AppLink color="primaryBright" path="/" label="Go Home" />
                <Link color="primaryBright" href={`mailto:${AppConfig.client.helpEmail}`} sx={{ display: "block" }}>Need Assistance?</Link>
            </Box>
        );
    } else {
        return (<Navigate to={"/signin"} />);
    }


}