import { Box } from "@mui/material";
import { useAppDispatch } from "src/app/hooks";
import { doSignOutAsync } from "src/features/auth/authSlice";

export default function SignOutPage() {    
    const dispatch = useAppDispatch();    
    dispatch(doSignOutAsync());
    
    return (
        <Box sx={{width: "100%", textAlign: "center"}}>... signing out ...</Box>        
    );
}