import Box from "@mui/material/Box";
import { ReactNode, RefObject } from "react";
import { RouterProvider, useLocation, useOutlet } from "react-router";
import { createBrowserRouter } from "react-router";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Header from "src/features/nav/Header";
import { appRoutes } from "src/features/nav/appRoutes";
import { NotFoundPage } from "./features/nav/NotFoundPage";
import AuthRouteElementWrapper from "./features/auth/AuthRouteElementWrapper";

const AppContainer = ({ children }: { children: ReactNode; }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                minHeight: "100vh",
                maxWidth: "1200px",
                backgroundColor: "white",
                "@media screen and (min-width: 1200px)": {
                    marginLeft: "auto",
                    marginRight: "auto",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                }
            }}
        >
            {children}
        </Box>
    );
};
const ContentContainer = ({ children }: { children: ReactNode; }) => {
    return (
        <Box sx={{
            margin: "0 2rem 0 2rem",
            paddingBottom: "1rem",
            "& li": { marginBottom: "0.5rem" },
            "@media (max-width: 650px)": { margin: "0 1rem 0 1rem" },
            "@media (max-width: 320px)": { margin: "0 0.5rem 0 0.5rem" }
        }}>
            {children}
        </Box>
    );
};

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <AppFadeIn />,
            errorElement: <AppContainer><Header /><ContentContainer><NotFoundPage /></ContentContainer></AppContainer>,
            children: appRoutes.map((route) => ({
                index: route.path === "/",
                path: route.path === "/" ? undefined : route.path,
                element: route.auth ? <AuthRouteElementWrapper children={route.element} /> : route.element,
            }))
        }],
    {
        future: {
            v7_relativeSplatPath: true,
            v7_startTransition: true
        }
    }
);

export default function App() {

    return (
        <RouterProvider router={router} />
    );
}

function AppFadeIn() {
    const location = useLocation();
    const currentOutlet = useOutlet();

    const { nodeRef } =
        appRoutes.find((route) => route.path === location.pathname) as { nodeRef: RefObject<HTMLDivElement>; } || {};

    return (
        <AppContainer>
            <Header />
            <ContentContainer>
                <TransitionGroup>
                    <CSSTransition
                        key={location.pathname}
                        timeout={300}
                        classNames="contentfade" //Defined in <GlobalStyles> (index.tsx)  
                        nodeRef={nodeRef}
                        unmountOnExit
                    >
                        <div ref={nodeRef} className="contentfade">{currentOutlet}</div>
                    </CSSTransition>
                </TransitionGroup>
            </ContentContainer>
        </AppContainer>
    );
}
