import { useAppSelector } from "src/app/hooks";
import { appRoutes } from "./appRoutes";
import Banner from "./Banner";
import { HeaderContainer, HeaderContent, LowerContainer, NavMenuWrapper, UpperContainer } from "./headerComponents";
import TopNavMenu from "./TopNavMenu";
import { isAppRouteAuthorized } from "./isAppRouteAuthorized";

export default function Header() {    
    const authUser = useAppSelector(state => state.auth.user);   
    const filteredRoutes = appRoutes.filter(route => isAppRouteAuthorized(route, authUser) && route.inMenu);        
    
    return (
        <HeaderContainer>
            <HeaderContent>
                <UpperContainer>
                    <Banner />
                </UpperContainer>
                <LowerContainer>
                    <NavMenuWrapper>
                        <TopNavMenu routes={filteredRoutes} />
                    </NavMenuWrapper>
                </LowerContainer>
            </HeaderContent>
        </HeaderContainer>
    );
}