import { createTheme } from "@mui/material";
//TODO: Make this file the only one necessary to modify global styles (get rid of app.css)
//TODO: Swap all styled components throughout app to sx

declare module "@mui/material/styles" {
    interface Palette {
        palette: {
            secondaryAlt: Palette["primary"];
            primaryBright: Palette["primary"];
        };
    }
    interface PaletteOptions {
        secondaryAlt: PaletteOptions["primary"];
        primaryBright: PaletteOptions["primary"];
    }
}

//primary values rgb(20, 45, 200), #173049

const spacingBase = 8;
const spacing = (factor: number) => `${spacingBase * factor}px`;

const appTheme = createTheme({
    spacing: spacingBase,
    palette: {
        primaryBright: {
            main: "rgb(20,45,200)",
        },
        primary: {
            main: "#173049",
        },
        secondary: {
            main: "#F2E737",
        },
        secondaryAlt: {
            main: "#94BDE1"
        }
    },
    typography: {
        fontFamily: "Roboto, Arial, sans-serif",
    },
    components: {
        MuiButton: {
            styleOverrides: {                
                root: {                    
                    fontWeight: 400
                }
            }
        },        
        MuiFormControl: {
            defaultProps: {
                variant: "filled"
            },
            styleOverrides: {
                root: {
                    display: "block",
                    // marginTop: spacing(2),
                    marginBottom: spacing(2),
                    variants: [
                        {
                            props: { variant: "filled" },
                            style: {
                                backgroundColor: "#eaf3fc"
                            }
                        }
                    ]
                }
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: "filled"
            },
            styleOverrides: {
                root: {
                    backgroundColor: "#eaf3fc"
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: "filled"
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    marginTop: "1rem"
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: "#eaeef1",
                }
            }
        },
        MuiList: {
            styleOverrides: {
                padding: {
                    paddingTop: "0"
                }
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                sticky: {
                    fontSize: "1rem",
                    backgroundColor: "#DDE1E4",
                    color: "#173049",
                    borderTop: "1px solid #5F7180",
                    borderBottom: "1px solid #5F7180"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 600
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: "hover",
            },
        },
    }
});

export { appTheme };
