import Box, { type BoxProps } from "@mui/material/Box";

//TODO Investigate using theme values for some of the calculations (16px == 1rem)?
//TODO Get colors from Theme
export function HeaderContainer(props: BoxProps) {
    const { sx, ...rest } = props;
    return (
        <Box
            sx={[
                {
                    width: "100%",
                    position: "sticky",
                    zIndex: 1250,
                    top: "-16px",
                    height: "calc(148px + 16px)",
                    "@media (max-width: 600px)": { height: "calc(108px + 16px)"},
                    "&:before, &:after" : { 
                        content: "''", 
                        display: "block", 
                        height: "16px", 
                        position: "sticky" 
                    },
                    "&:before": {
                        top: "calc(148px - 16px)",
                        boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2)",
                        width: "calc(100% -5px)",
                        "@media (max-width: 600px)": { top: "calc(108px - 16px)" }
                    },
                    "&:after": {
                        background: "linear-gradient(white 10%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.4) 70%, transparent)",
                        top: 0,
                        zIndex: 1251
                    }
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        />
    );
}

export function UpperContainer(props: BoxProps) {
    const { sx, ...rest } = props;
    return (
        <Box
            sx={[
                {
                    width: "100%",
                    height: "100px",
                    backgroundColor: "#FFFFFF",
                    display: "-webkit-box",
                    flexDirection: "column",
                    "@media (max-width: 600px)": { height: "60px" }
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        />
    );
}

export function LowerContainer(props: BoxProps) {
    const { sx, ...rest } = props;
    return (
        <Box
            sx={[
                {
                    width: "100%",
                    borderBottom: "1px solid #5E7180",
                    borderTop: "1px solid #5E7180",
                    backgroundColor: "#DDE1E4"
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        />
    );
}

export function NavMenuWrapper(props: BoxProps) {  
    const { sx, ...rest } = props;
    return (
        <Box
            sx={[
                {
                    width: "calc(100% - 165px)",
                    height: "3rem",
                    marginLeft: "165px",
                    backgroundColor: "#DDE1E4",
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        />
    );
}

export function HeaderContent(props: BoxProps) {
    const { sx, ...rest } = props;
    return (
        <Box
            sx={[
                {                    
                    height: "148px",
                    position: "sticky",
                    top: "0px",
                    marginTop: "-16px",
                    zIndex: 1252,
                    "@media (max-width: 600px)": { height: "108px" }
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            {...rest}
        />
    );
}