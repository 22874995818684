import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CloseIcon from "@mui/icons-material/Close";
import GlobalStyles from "@mui/material/GlobalStyles";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SnackbarKey, SnackbarProvider } from "notistack";
import  { createRef, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import App from "src/App";
import { store } from "src/app/store";
import userManager from "src/features/auth/oidcConfig";
import { appTheme } from "src/Theme";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";

const notistackRef = createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey | undefined) => () => {
    notistackRef?.current?.closeSnackbar(key);
};

createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <GlobalStyles styles={{
            body: {
                backgroundImage: "linear-gradient(#173049, #5E7180)",
                backgroundAttachment: "fixed",
                fontFamily: "Roboto, sans-serif",
            },
            // classes for <CSSTransition> in App.tsx
            ".contentfade": {},
            ".contentfade-enter": { opacity: 0 },
            ".contentfade-enter-active": { opacity: 1, transition: "opacity 300ms" },
            ".contentfade-exit": { opacity: 0 },
            ".contentfade-exit-active": { opacity: 0, transition: "opacity 300ms" }
        }} />
        <Provider store={store}>
            {/* @ts-expect-error type inference issue */}  
            <OidcProvider store={store} userManager={userManager}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <ThemeProvider theme={appTheme}>
                        <CssBaseline />
                        <SnackbarProvider
                            ref={notistackRef}
                            maxSnack={2}
                            autoHideDuration={10000}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                            }}
                            action={(key) => (
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    onClick={onClickDismiss(key)}
                                    size="large">
                                    <CloseIcon />
                                </IconButton>
                            )}
                        >
                            <App />
                        </SnackbarProvider>
                    </ThemeProvider>
                </LocalizationProvider>
            </OidcProvider>
        </Provider>
    </StrictMode>
);